<template>
  <div class="turnable_outSide">
    <div class="title_box">
      <div class="title"></div>
      <div class="title1">— 抢百元美食券 —</div>
    </div>
    <div class="turnable_box">
      <div class="turnable" ref="turnable"></div>
      <van-button class="tupBtn" :disabled="btnDis" @click.stop="run" id="turnable"></van-button>
    </div>
    <div class="tips">
      <div class="tip_title">
        <div class="tl"></div>
        <div class="tc">活动规则</div>
        <div class="tr"></div>
      </div>
      <div class="tip1 tipfs">1.活动时间：2021年6月1日至2021年6月20日。</div>
      <div class="tip2 tipfs">2.活动时间内，用户可进行转盘游戏，抽中后将获得对应菜系的满减优惠券；凯迪APP内的优惠券消息通知，将作为唯一领取凭证。</div>
    </div>
    <div id="wx-mark" v-if="wxMarkShow" @click="wxClose">
      <div class="mark" id="mark">
        <div class="yd_tip">
          <p class="yd_more">
            1 点击右上角
            <img src="http://cdn.9kd.com/kdnet/activicty_20210122/yd_more.png" alt="" />
          </p>
          <p>2 选择在浏览器中打开</p>
        </div>
        <div class="yd_img">
          <img src="http://cdn.9kd.com/kdnet/activicty_20210122/jt_yd.png" alt="" />
        </div>
      </div>
    </div>
    <div class="phone_all">
      <div class="mask" v-if="maskShow"></div>
      <div class="phone_dialog" v-if="phoneDiaShow">
        <div class="cancle_icon" @click="closePhoneDia"></div>
        <div class="title">
          <p>恭喜您获得赣菜代表赣府酒家</p>
          <p>提供的满200元减100元优惠券</p>
        </div>
        <div class="small">全国范围内赣府均可使用！</div>
        <input type="number" class="phone_input" placeholder="用手机号可领取" @input="phoneInput" ref="phoneNum" max="99999999999" />
        <van-button class="phone_button" :disabled="submitDis" @click="submitPhone">确认领取</van-button>
      </div>
    </div>
    <!-- <button style="position: fixed; top: 20%; width: 60px" @click="reback">还原测试按钮</button> -->
  </div>
</template>
<script>
import { Dialog } from 'vant'
export default {
  name: 'turnable',
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      maskShow: false,
      btnDis: false,
      footerShow: true,
      wxMarkShow: false,
      downTimer: null,
      openTimer: null,
      submitDis: true,
      phoneDiaShow: false,
    }
  },

  created() {
    // 微信二次分享，全局混入函数
    this.setWeChatShare({
      url: window.location.host + window.location.pathname,
      title: '十大菜系优惠券等你领',
      description: '免费转盘游戏抢百元美食券，快来一起拼手速吧！',
      friendImg: 'http://cdn.9kd.com/kdnet/shareTurnableImg.png',
      momentsImg: 'http://cdn.9kd.com/kdnet/shareTurnableImg.png'
    });
  },
  beforeDestroy() {
    this.clearTimer()
  },

  methods: {
    //输入手机号
    phoneInput() {
      let Input = this.$refs.phoneNum
      if (Input.value.length === 11) {
        this.submitDis = false
      } else if (Input.value.length < 11) {
        this.submitDis = true
      } else {
        Input.value = Input.value.slice(0, 11)
      }
    },
    //确认领取
    submitPhone() {
      let Input = this.$refs.phoneNum.value.trim()
      if (/^1[3456789]\d{9}$/.test(Input)) {
        let params = {
          phone: Input * 1,
          type: 1,
        }
        this.$api
          .getCoupon(params)
          .then((res) => {
            if (res.data.code !== 200) {
              this.$toast.fail(res.data.msg)
              return
            }
            this.$router.push('/received')
            this.maskShow = false
            this.reback()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$toast.fail('你的手机号输入有误')
      }
    },
    //关闭弹窗
    closePhoneDia() {
      Dialog.confirm({
        message: '关闭后无法领取奖励，确认关闭吗？',
      })
        .then(() => {
          this.reback()
          this.maskShow = false
          this.phoneDiaShow = false
        })
        .catch(() => {
          // on cancel
        })
    },
    run() {
      this.btnDis = true
      window._hmt = window._hmt || []
      window._hmt.push(['_trackEvent', '参与人数', '点击', '点击转盘']) //赣菜分享事件跟踪
      let angle = 0
      //据ip 检查用户 当天是否抽过奖
      this.$api
        .checkIsJoin()
        .then((res) => {
          if (res.data.code !== 200) {
            this.$toast.fail(res.data.msg)
            return
          }
          // 1已转 0未转
          if (res.data.data.isDraw) {
            this.$toast.fail('今天的转盘次数已用完，明天再来吧')
            this.btnDis = false
            return
          }
          this.$api
            .turnableSave()
            .then((res1) => {
              //存在优惠券发放完的情况code!==200
              if (res1.data.code !== 200) {
                this.$toast.fail(res1.data.msg)
                this.btnDis = false
                return
              }
              this.btnDis = true
              //1800+36*8
              angle = 2089 + Math.random() * 34
              this.rotate(angle)
              setTimeout(() => {
                this.maskShow = true
                this.phoneDiaShow = true
                this.btnDis = false
              }, 5300)
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //转盘复原
    reback() {
      let tur = this.$refs.turnable
      tur.style.transition = 'transform 0s'
      tur.style.transform = 'rotate(0)'
    },
    //旋转
    rotate(angle) {
      this.btnDis = true
      let tur = this.$refs.turnable
      tur.style.transition = 'transform 5s'
      tur.style.transform = 'rotate(' + angle + 'deg)'
    },
    //下载app
    openApp(url) {
      let { isAndroid, isIOS, isIOS9 } = this.judgePhoneType()
      if (this.isWeiXin()) {
        this.wxMarkShow = true
        return
      }
      this.clearTimer()
      if (isAndroid) {
        if (this.isQQBrowser()) {
          this.dialogShow = true
          return
        }
        let hasApp = true,
          t = 1000,
          t1 = Date.now(),
          ifr = document.createElement('iframe')
        this.downTimer = setTimeout(function () {
          // 未安装
          if (!hasApp) {
            // 安卓没有安装直接跳转到下载页面
            window.location.href = 'https://www.9kd.com/kd-net/kd_download/kd_download.html'
          }
          document.body.removeChild(ifr)
        }, 2000)
        ifr.setAttribute('src', url)
        ifr.setAttribute('style', 'display:none')
        document.body.appendChild(ifr)

        this.openTimer = setTimeout(function () {
          //启动app时间较长处理
          let t2 = Date.now()
          if (t2 - t1 < t + 100) {
            hasApp = false
          }
        }, t)
      }
      if (isIOS9 || isIOS) {
        window.location = 'https://apps.apple.com/cn/app/%E5%87%AF%E8%BF%AA%E6%96%B0%E9%97%BB-%E6%9C%89%E4%BD%A0-%E6%9B%B4%E6%9C%89%E5%BD%B1%E5%93%8D%E5%8A%9B/id1533642383'
      }
    },
    //判断手机类型
    judgePhoneType() {
      let isAndroid = false,
        isIOS = false,
        isIOS9 = false,
        version,
        u = navigator.userAgent,
        ua = u.toLowerCase()
      //Android系统
      if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        //android终端或者uc浏览器
        isAndroid = true
      }
      //ios
      if (ua.indexOf('like mac os x') > 0) {
        let regStr_saf = /os [\d._]*/gi
        let verinfo = ua.match(regStr_saf)
        version = (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.')
      }
      let version_str = version + ''
      // ios9以上
      if (version_str !== 'undefined' && version_str.length > 0) {
        version = parseInt(version)
        if (version >= 8) {
          isIOS9 = true
        } else {
          isIOS = true
        }
      }
      return { isAndroid, isIOS, isIOS9 }
    },
    //判断是否是微信浏览器
    isWeiXin() {
      return /micromessenger/i.test(navigator.userAgent.toLowerCase()) || typeof navigator.wxuserAgent !== 'undefined'
    },
    //微信遮罩关闭
    wxClose() {
      this.wxMarkShow = false
    },
    //QQ浏览器
    isQQBrowser() {
      return /mqqbrowser/i.test(navigator.userAgent.toLowerCase())
    },
    //清除定时器
    clearTimer() {
      if (this.downTimer) {
        clearTimeout(this.downTimer)
      }
      if (this.openTimer) {
        clearTimeout(this.openTimer)
      }
    },
  },
}
</script>

<style scoped lang='less'>
* {
  box-sizing: border-box;
}
.turnable_outSide {
  width: 100%;
  min-height: calc(100vh);
  background: url('http://cdn.9kd.com/%E8%83%8C%E6%99%AF%402x.png') no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
  position: relative;
  .title_box {
    width: 100%;
    height: 122px;
    padding: 38px 0 6.5px 0;

    .title {
      width: 279px;
      height: 51px;
      background: url('http://cdn.9kd.com/kdnet/-e-%E6%A0%87%E9%A2%98%402x.png') no-repeat;
      background-size: 100%;
      margin: 0 auto 6.5px auto;
    }
    .title1 {
      margin: 0 auto;
      width: 167px;
      height: 20px;
      font-size: 19px;
      font-weight: 500;
      color: #fff;
      text-shadow: 0px 3px 1px rgba(172, 48, 2, 0.44);
    }
  }

  .turnable_box {
    width: 331px;
    height: 334.5px;
    position: relative;
    margin: 0 auto 45px auto;
    /deep/.van-button--disabled {
      opacity: 0.9 !important;
    }
    .turnable {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: url('http://cdn.9kd.com/%E8%BD%AC%E7%9B%98%402x.png') no-repeat;
      background-size: 100% 100%;
      // -o-transform: transform 5s;
      // -ms-transform: transform 5s;
      // -moz-transform: transform 5s;
      // -webkit-transform: transform 5s;
      transition: transform 5s;
      -o-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    .tupBtn {
      width: 104.5px;
      height: 125.5px;
      background: url('http://cdn.9kd.com/kdnet/%E8%BD%AC%E8%8A%AF%402x.png') no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 100px;
      text-align: center;
      border: none;
    }
  }
  .tips {
    width: 351px;
    height: 160px;
    background: url('http://cdn.9kd.com/kdnet/%E5%9C%86%E8%A7%92%E7%9F%A9%E5%BD%A2%402x.png') no-repeat;
    background-size: 100%;
    margin: 0 auto 59px auto;
    padding-top: 17px;
    .tip_title {
      width: 140px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .tl {
        width: 28.5px;
        height: 10px;
        background: url('http://cdn.9kd.com/kdnet/turnableLeftPoint%402x.png') no-repeat;
        background-size: 100%;
      }
      .tc {
        color: #f66436;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        margin: 0 10px;
      }
      .tr {
        width: 28.5px;
        height: 10px;
        background: url('http://cdn.9kd.com/kdnet/turnableRightPoint%402x.png') no-repeat;
        background-size: 100%;
      }
    }
    .tipfs {
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      line-height: 18px;
      color: #666666;
      width: 274px;
      margin: 14px auto 0 auto;
    }
  }
  .footer {
    box-sizing: border-box;
    width: 100%;
    height: 59px;
    padding: 10px 16.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99;
    .f-left {
      opacity: 1;
      display: flex;
      align-items: center;
      img {
        z-index: 99;
        width: 39px;
        height: 39px;
      }
      p {
        text-align: left;
        margin-left: 11.5px;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 500;
        color: #fff;
        display: flex;
        flex-direction: column;
        .app {
          font-size: 12px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
    .f-right {
      width: 77.5px;
      height: 29px;
      background: #f7321c;
      border-radius: 15px;
      .open-btn {
        opacity: 1;
        font-size: 12px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: -1px;
        text-align: center;
        line-height: 29px;
      }
    }
  }
  #wx-mark {
    .mark {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      display: flex;
      justify-content: flex-end;
    }
    .yd_tip {
      margin: 10px 20px 0 0;
      color: white;
      font-size: 14px;
      line-height: 24px;
    }
    .yd_tip .yd_more {
      display: flex;
      align-items: baseline;
    }
    .yd_tip .yd_more img {
      width: auto;
      height: 3px;
      margin-left: 2px;
    }
    .yd_img {
      margin: 10px 20px 0 0;
      width: 50px;
      height: 34.7px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dialog {
    width: 300px;
    height: 120px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 15px;
    z-index: 100;
  }
  .phone_all {
    width: 100%;
    height: 100%;
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 99;
    }
    .phone_dialog {
      text-align: center;
      width: 299px;
      height: 261.5px;
      background: url('http://cdn.9kd.com/kdnet/turnableDialog%402x.png') no-repeat;
      background-size: 100%;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      .cancle_icon {
        width: 10px;
        height: 10px;
        position: absolute;
        right: 10px;
        top: 10px;
        background: url('http://cdn.9kd.com/kdnet/turnableCancle%402x.png') no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
      .title {
        width: 214px;
        // height: 38.5px;
        margin: 26px auto 10px auto;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
      }
      .small {
        // width: 146.5px;
        opacity: 0.8;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #fffefe;
        margin: 0 auto;
      }
      .phone_input {
        font-size: 14px;
        width: 264px;
        height: 39px;
        opacity: 0.5;
        background: #e4e4e4;
        border-radius: 2.5px;
        margin: 44px auto 17px auto;
        outline: none;
        padding-left: 12.5px;
        border: none;
      }
      input::-webkit-input-placeholder {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;

        color: #999999;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
      }
      .phone_button {
        width: 264px;
        height: 39px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        background: #f77229;
        border-radius: 2.5px;
        outline: none;
        border: none;
      }
    }
  }
  .toast {
    width: 110px !important;
    max-width: 70%;
    min-height: 110px !important;
    text-align: center;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
  }
}
</style>
